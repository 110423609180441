import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Box as MuiBox,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  return (
    <>
      <AppBar position="static" style={{ height: "75px" }}>
        <Container maxWidth="md">
          <Toolbar>
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              <img
                src={
                  prefersDarkMode
                    ? "/images/amidasan-logo-dark.png"
                    : "/images/amidasan-logo.png"
                }
                alt="あみださん"
                style={{ width: "150px" }}
              />
            </Link>
            <MuiBox component="div" m={2} />
            <Typography
              variant="subtitle2"
              style={{
                wordBreak: "keep-all",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%", // 必要に応じて調整
              }}
            >
              参加型 あみだくじ ランダム 抽選
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
      <div style={{ marginBottom: "10px" }} />
    </>
  );
};

export default Header;
