import {
  Box as MuiBox,
  Button,
  CircularProgress,
  Typography,
  Paper,
  Fade,
  TextField,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AmidaEventState } from "../../../domain/types/Event";
import { FetchStatus } from "../../../domain/types/FetchStatus";
import NotFound from "../../common/NotFound";
import DoneIcon from "@mui/icons-material/Done";

type bodyProps = {
  amidaEventState: AmidaEventState;
};

const Body = (props: bodyProps) => {
  const amidaEventState = props.amidaEventState;
  const amidaEvent = amidaEventState.amidaEvent;
  const status = amidaEventState.status;
  const navigate = useNavigate();

  const [showDoneIcon, setShowDoneIcon] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDoneIcon(true);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  const handleCopy = () => {
    copyToClipboard(`${window.location.origin}/e/${amidaEvent.id}`);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  const copyToClipboard = (text: string) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).catch((err) => {
        // クリップボードAPIが利用できない場合のフォールバック
        const textarea = document.createElement("textarea");
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
      });
    } else {
      // クリップボードAPIが全く利用できない場合
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    }
  };

  switch (status) {
    case FetchStatus.SUCCEEDED:
      return (
        <Paper>
          <MuiBox
            component="div"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h1">さくせい完了</Typography>
            <MuiBox component="div" m={0.5} />
            {showDoneIcon ? (
              <Fade in={showDoneIcon} timeout={777}>
                <DoneIcon color="secondary" fontSize="large" />
              </Fade>
            ) : (
              <CircularProgress size={35} />
            )}
          </MuiBox>
          <MuiBox m={2} component="div" />
          <Typography variant="body2">
            タイトル: {amidaEvent.title?.val}
            <br />
            横棒の本数: {amidaEvent.bridgeNumRange?.max}
            <br />
            振り分け:{" "}
            {amidaEvent.playerLabels.length !== 0
              ? amidaEvent.playerLabels
                  .map((label) => label.labelName)
                  .join(", ")
              : "数字 (1, 2, 3, ...)"}
          </Typography>
          <MuiBox m={2} component="div" />
          <Typography variant="body1">
            まずは、あみだページのURLを参加者に共有しましょう
          </Typography>

          <MuiBox m={2} component="div" />

          <MuiBox
            component="div"
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <TextField
              id="outlined-basic"
              variant="outlined"
              defaultValue={window.location.origin + "/e/" + amidaEvent.id}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              sx={{ height: "56px" }}
            />

            <MuiBox m={0.1} component="div" />

            <Button
              sx={{
                width: "100px",
                height: "56px",
                borderRadius: "2px",
                shadow: "none",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                },
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                handleCopy();
              }}
            >
              {!copied && <ContentCopyIcon fontSize="medium" />}
              {copied && <DoneIcon fontSize="medium" />}
            </Button>
          </MuiBox>

          <MuiBox m={2} component="div" />

          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => navigate("/e/" + amidaEventState.amidaEvent.id)}
          >
            <Typography variant="body1">あみだくじに移動する</Typography>
          </Button>
        </Paper>
      );
    case FetchStatus.FAILED:
      return <NotFound />;
    default:
      return (
        <MuiBox textAlign="center" component="div">
          <CircularProgress />
        </MuiBox>
      );
  }
};

export default Body;
