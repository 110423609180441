import React, { useRef } from "react";
import { Typography, TextField, Box as MuiBox, Button } from "@mui/material";
import PlayerLabelsForm from "./PlayerLabelsForm";
import BridgePreview from "./BridgePreview";
// import NumericField from "./NumericField";

type eventFormProps = {
  setEventTitle: React.Dispatch<React.SetStateAction<string>>;
  setBridgeNumRangeMin: React.Dispatch<React.SetStateAction<number>>;
  setBridgeNumRangeMax: React.Dispatch<React.SetStateAction<number>>;
  setPlayerLabelWithErrors: React.Dispatch<
    React.SetStateAction<{ label: string; error: boolean }[]>
  >;
  eventTitle: string;
  bridgeNumRangeMin: number;
  bridgeNumRangeMax: number;
  playerLabelWithErrors: { label: string; error: boolean }[];
  // maxJoinedUsersNum: number;
  // setMaxJoinedUsersNum: React.Dispatch<React.SetStateAction<number>>;
};

const EventForm = (props: eventFormProps) => {
  const {
    setEventTitle,
    // setBridgeNumRangeMin,
    setBridgeNumRangeMax,
    setPlayerLabelWithErrors,
    eventTitle,
    // bridgeNumRangeMin,
    bridgeNumRangeMax,
    playerLabelWithErrors,
    // maxJoinedUsersNum,
    // setMaxJoinedUsersNum,
  } = props;
  //最低限も決められる機能は不要なのでコメントアウト
  // const handleBridgeNumRangeChange = (
  //   event: any,
  //   newValue: number | number[]
  // ) => {
  //   if (typeof newValue === "number") {
  //     return;
  //   }
  //   setBridgeNumRangeMin(newValue[0]);
  //   setBridgeNumRangeMax(newValue[1]);
  // };

  //slider を使わなくなったので、コメントアウト
  // const handleBridgeNumRangeChange = (
  //   event: any,
  //   newValue: number | number[]
  // ) => {
  //   if (typeof newValue !== "number") {
  //     return;
  //   }
  //   setBridgeNumRangeMin(2);
  //   setBridgeNumRangeMax(newValue);
  // };

  const textFieldRef = useRef<HTMLInputElement>(null);
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault(); // デフォルトの動作を防止
      textFieldRef.current?.blur(); // フォーカスを解除
    }
  };

  return (
    <>
      <MuiBox component="div">
        <Typography variant="h1">タイトル</Typography>
        <TextField
          id="outlined-basic"
          label="例: 抽選会"
          fullWidth
          variant="outlined"
          value={eventTitle}
          onChange={(e) => setEventTitle(e.target.value)}
          inputRef={textFieldRef}
          onKeyDown={handleKeyDown}
          inputProps={{
            enterKeyHint: "done",
            type: "text",
          }}
        />
      </MuiBox>

      {/* <MuiBox component="div">
        <Typography variant="h1">最大参加人数</Typography>
        <NumericField
          id="max-joined-users-num-input"
          state={maxJoinedUsersNum}
          setState={setMaxJoinedUsersNum}
          label="最大参加人数"
          variant="outlined"
          style={{ width: "200px" }}
          required
          fullWidth
          onKeyDown={handleKeyDown}
        />
      </MuiBox> */}

      <MuiBox m={3} component="div" />

      <PlayerLabelsForm
        playerLabelWithErrors={playerLabelWithErrors}
        setPlayerLabelWithErrors={setPlayerLabelWithErrors}
      />

      <MuiBox m={3} component="div" />

      <MuiBox component="div">
        <Typography variant="h1">横棒の本数</Typography>
        <BridgePreview
          bridgeNumRangeMax={bridgeNumRangeMax}
          playerLabels={playerLabelWithErrors.map(
            (playerLabelWithError) => playerLabelWithError.label
          )}
        />
        <Typography variant="body1" color="textSecondary">
          ひとりの参加者が伸ばす横棒の数を設定できます
        </Typography>
        <MuiBox display="flex" alignItems="center" component="div">
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              const newValue = Math.max(2, bridgeNumRangeMax - 1);
              setBridgeNumRangeMax(newValue);
            }}
          >
            <Typography variant="body2">-</Typography>
          </Button>
          <Typography variant="body1" style={{ margin: "0 10px" }}>
            {bridgeNumRangeMax}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              const newValue = Math.min(5, bridgeNumRangeMax + 1);
              setBridgeNumRangeMax(newValue);
            }}
          >
            <Typography variant="body2">+</Typography>
          </Button>
        </MuiBox>
      </MuiBox>
    </>
  );
};

export default EventForm;
