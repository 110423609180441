import React, { useRef } from "react";
import {
  Typography,
  TextField,
  Button,
  Box as MuiBox,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

type playerLbelsFormProps = {
  playerLabelWithErrors: { label: string; error: boolean }[];
  setPlayerLabelWithErrors: React.Dispatch<
    React.SetStateAction<{ label: string; error: boolean }[]>
  >;
};

const PlayerLabelsForm = (props: playerLbelsFormProps) => {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault(); // デフォルトの動作を防止
      textFieldRef.current?.blur(); // フォーカスを解除
    }
  };

  return (
    <MuiBox component="div">
      <Typography variant="h1">振り分け</Typography>
      {props.playerLabelWithErrors.length === 0 && (
        <Typography variant="body1" color="textSecondary">
          追加なしの場合は、数字 (1, 2, 3, ...) で振り分けられます
        </Typography>
      )}
      <List aria-label="player labels" style={{ padding: 0 }}>
        {props.playerLabelWithErrors.map((playerLabelWithError, index) => (
          <ListItem
            key={index}
            sx={{
              paddingBottom: 0,
              paddingTop: 0,
              "&:not(:last-child)": {
                marginBottom: 0,
              },
            }}
          >
            <TextField
              id="outlined-basic"
              label={index === 0 ? "例: 大当たり" : "例: 当たり"}
              value={playerLabelWithError.label}
              variant="outlined"
              error={playerLabelWithError.error}
              helperText={
                playerLabelWithError.error ? "振り分け名を短くしてください" : ""
              }
              onChange={(e) => {
                const newPlayerLabelWithErrors = [
                  ...props.playerLabelWithErrors,
                ];
                newPlayerLabelWithErrors[index] = {
                  label: e.target.value,
                  error: e.target.value.length > 20,
                };
                props.setPlayerLabelWithErrors(newPlayerLabelWithErrors);
              }}
              inputRef={textFieldRef}
              onKeyDown={handleKeyDown}
              inputProps={{
                enterKeyHint: "done",
                type: "text",
              }}
              sx={{ height: "56px" }}
            />

            <MuiBox m={0.1} component="div" />

            <ListItemIcon>
              <Button
                sx={{
                  height: "56px",
                  borderRadius: "2px",
                  shadow: "none",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
                variant="contained"
                color="secondary"
                onClick={() => {
                  const newPlayerLabelWithErrors = [
                    ...props.playerLabelWithErrors,
                  ];
                  newPlayerLabelWithErrors.splice(index, 1);
                  props.setPlayerLabelWithErrors(newPlayerLabelWithErrors);
                }}
              >
                <DeleteIcon />
              </Button>
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          const newPlayerLabelWithErrors = [...props.playerLabelWithErrors];
          newPlayerLabelWithErrors.push({ label: "", error: false });
          props.setPlayerLabelWithErrors(newPlayerLabelWithErrors);
        }}
      >
        <Typography variant="body1">振り分けを追加</Typography>
      </Button>
    </MuiBox>
  );
};

export default PlayerLabelsForm;
