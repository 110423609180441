import React, { useRef, useState } from "react";
import { AmidaEvent } from "../../../domain/types/Event";
import { User } from "../../../domain/types/User";
import useEventPageSuccessOpen from "../../hooks/useEventPageSuccessOpen";
import {
  Box as MuiBox,
  Button,
  TextField,
  Typography,
  IconButton,
  Paper,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Fade,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ShareEvent from "../../common/ShareEvent";
import {
  enableEditing,
  getEvent,
  lockUpdateEndEvent,
  unlockUpdateEndEvent,
  unlockUpdateEndEventByAdminUser,
} from "../../../domain/features/EventSlice";
import { useDispatch } from "react-redux";
import SyncIcon from "@mui/icons-material/Sync";
import AmidaInput from "./AmidaInput";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DoneIcon from "@mui/icons-material/Done";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AboutAmidasanForJoin from "./AboutAmidasanForJoin";
import ScrollToTop from "../../common/ScrollToTop";

type Props = {
  amidaEvent: AmidaEvent;
  amidaEventTimestamp: string;
  user: User;
};

const SuccessOpen = (props: Props) => {
  const { amidaEvent, amidaEventTimestamp, user } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    isAdmin,
    isJoined,
    displayName,
    setDisplayName,
    myBridges,
    setMyBridges,
    selectedMyBridgeIndex,
    setSelectedMyBridgeIndex,
    inputPassword,
    setInputPassword,
    joinEvent,
    updateJoinedUser,
    leaveEvent,
    endEvent,
  } = useEventPageSuccessOpen(amidaEvent, user);

  const getCandidateLabel = (playersLength: number, playerLabels: string[]) => {
    if (playerLabels.length === 0 && playersLength === 0) {
      return "数字 (1, 2, 3, ...)";
    }

    if (playerLabels.length >= playersLength) {
      return playerLabels.join(", ");
    }

    const val = [...Array(playersLength)].map((_, index) =>
      index < playerLabels.length ? playerLabels[index] : index + 1
    );
    return val.join(", ");
  };

  const textFieldRef = useRef<HTMLInputElement>(null);
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault(); // デフォルトの動作を防止
      textFieldRef.current?.blur(); // フォーカスを解除
    }
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleConfirmDialogOpen = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmEndEvent = () => {
    endEvent();
    handleConfirmDialogClose();
  };

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const handleSyncClick = async () => {
    if (!amidaEvent.id) return;
    setLoading(true);
    try {
      const resEvent = await dispatch(getEvent(amidaEvent.id) as any);
      if (!resEvent.payload.event.id) {
        throw new Error();
      }
    } catch (e) {
      alert("最新情報の取得に失敗しました");
      return;
    }
    setTimeout(() => {
      setLoading(false);
      setDone(true);
      setTimeout(() => {
        setDone(false);
      }, 1500);
    }, 1000);
  };

  const isBlockUpdateWithoutAdmin: boolean =
    amidaEvent.isBlockUpdateWithoutAdmin;
  const isBlockEndWithoutAdmin: boolean = amidaEvent.isBlockEndWithoutAdmin;
  const isBlockMessageTypography = () => {
    if (isBlockUpdateWithoutAdmin && isBlockEndWithoutAdmin) {
      return (
        <Typography variant="body1" color="textSecondary">
          管理者のみがあみだくじを編集・開始できます。
        </Typography>
      );
    }
    if (isBlockUpdateWithoutAdmin) {
      return (
        <Typography variant="body1" color="textSecondary">
          管理者のみがあみだくじを編集できます。
        </Typography>
      );
    }
    if (isBlockEndWithoutAdmin) {
      return (
        <Typography variant="body1" color="textSecondary">
          管理者のみがあみだくじを開始できます。
        </Typography>
      );
    }
    return null;
  };

  const isDisabledEditButton = () => {
    if (isBlockUpdateWithoutAdmin && !isAdmin) {
      return true;
    }
    return false;
  };

  const isDisabledEndButton = () => {
    if (isBlockEndWithoutAdmin && !isAdmin) {
      return true;
    }
    return amidaEvent.players.length < 2;
  };

  const isLockedUpadateEndEvent = amidaEvent.lockUpdateEnd?.isLock ?? false;

  const [openConfirmLockDialog, setOpenConfirmLockDialog] = useState(false);
  const [isDoneLockUpdateEndEvent, setIsDoneLockUpdateEndEvent] =
    useState(false);
  const [providedPassword, setProvidedPassword] = useState("");

  const handleConfirmLockDialogOpen = () => {
    setOpenConfirmLockDialog(true);
  };

  const handleConfirmLockDialogClose = () => {
    setOpenConfirmLockDialog(false);
    setTimeout(() => {
      setIsDoneLockUpdateEndEvent(false);
      setProvidedPassword("");
    }, 300); // モーダルが閉じるアニメーションの時間に合わせて調整
  };

  const handleConfirmLockUpdateEndEvent = async () => {
    if (!amidaEvent.id) return;
    if (!user.id) return;
    const res = await dispatch(
      lockUpdateEndEvent({
        eventId: amidaEvent.id,
        userId: user.id,
      }) as any
    );
    if (res.error) {
      alert("ロックに失敗しました");
      return;
    }
    const pass = res.payload.password;
    if (!pass) {
      alert("パスワードが生成できませんでした");
      return;
    }
    setProvidedPassword(pass);
    setIsDoneLockUpdateEndEvent(true);
  };

  const [openConfirmUnLockDialog, setOpenConfirmUnLockDialog] = useState(false);
  const [isDoneUnLockUpdateEndEvent, setIsDoneUnLockUpdateEndEvent] =
    useState(false);

  const handleConfirmUnLockDialogOpen = () => {
    setOpenConfirmUnLockDialog(true);
  };

  const handleConfirmUnLockDialogClose = () => {
    setOpenConfirmUnLockDialog(false);
    setTimeout(() => {
      setIsDoneUnLockUpdateEndEvent(false);
      setInputPassword("");
      setIsShowUnlockByAdminUserOnOpenConfirmUnLockDialog(false);
    }, 300); // モーダルが閉じるアニメーションの時間に合わせて調整
  };

  const handleConfirmUnLockUpdateEndEvent = async () => {
    if (!amidaEvent.id) return;
    if (!user.id) return;

    const res = await dispatch(
      unlockUpdateEndEvent({
        eventId: amidaEvent.id,
        password: inputPassword,
      }) as any
    );
    if (res.error) {
      alert("パスワードが違います");
      return;
    }
    setIsDoneUnLockUpdateEndEvent(true);
  };

  const [
    isShowUnlockByAdminUserOnOpenConfirmUnLockDialog,
    setIsShowUnlockByAdminUserOnOpenConfirmUnLockDialog,
  ] = useState(false);

  const handleConfirmUnLockUpdateEndEventByAdminUser = async () => {
    if (!amidaEvent.id) return;
    if (!user.id) return;

    const res = await dispatch(
      unlockUpdateEndEventByAdminUser({
        eventId: amidaEvent.id,
        adminUserId: user.id,
      }) as any
    );
    if (res.error) {
      alert("ロック解除に失敗しました");
      return;
    }
    setIsDoneUnLockUpdateEndEvent(true);
  };

  return (
    <>
      <ScrollToTop />

      <AboutAmidasanForJoin />

      <MuiBox component="div" m={3} />

      <Paper>
        <Typography variant="h1">
          {amidaEvent.title?.val} （参加受付中）
        </Typography>
        <Typography variant="body1">
          {amidaEvent.createdAt &&
            ` 振り分け: ${getCandidateLabel(
              amidaEvent.players.length,
              amidaEvent.playerLabels.map((pl) => pl.labelName)
            )}`}
        </Typography>

        <MuiBox component="div" m={1} />
        {/* 
        <Typography variant="body1" color="textSecondary">
          右と左に合わせて {amidaEvent.bridgeNumRange?.min}{" "}
          本以上の横棒を追加することで、参加できます
        </Typography> */}

        <AmidaInput
          amidaEvent={amidaEvent}
          user={user}
          myBridges={myBridges}
          setMyBridges={setMyBridges}
          enableEditing={enableEditing}
          selectedMyBridgeIndex={selectedMyBridgeIndex}
          setSelectedMyBridgeIndex={setSelectedMyBridgeIndex}
        />

        <MuiBox component="div">
          <Typography variant="h1">名前</Typography>
          <TextField
            fullWidth
            id="outlined-basic"
            label="名前を入力（例: たなか）"
            variant="outlined"
            value={displayName}
            onChange={(e) => {
              setDisplayName(e.target.value);
              dispatch(enableEditing());
            }}
            inputRef={textFieldRef}
            onKeyDown={handleKeyDown}
            inputProps={{
              enterKeyHint: "done",
              type: "text",
            }}
          />
        </MuiBox>

        <Typography variant="body1" color="textSecondary">
          名前は他の参加者にも表示されます
        </Typography>

        <MuiBox component="div" m={2} />

        {!isJoined && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => joinEvent()}
          >
            <Typography variant="body1">参加する</Typography>
          </Button>
        )}

        {isJoined && (
          <>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                updateJoinedUser();
              }}
            >
              <Typography variant="body1">更新する（参加済み）</Typography>
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => {
                leaveEvent();
              }}
            >
              <Typography variant="body1">退出する</Typography>
            </Button>
          </>
        )}

        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          transition={Flip}
        />
      </Paper>

      <MuiBox component="div" m={3} />

      <Paper>
        <MuiBox
          component="div"
          display="flex"
          bgcolor="warning.main"
          p={0.3}
          borderRadius={1}
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            style={{ flex: 1, textAlign: "center" }}
          >
            {isLockedUpadateEndEvent
              ? "編集・開始にはパスワードが必要です"
              : "誰でも、編集・開始ができます"}
          </Typography>

          <Tooltip
            title={isLockedUpadateEndEvent ? "ロックを解除する" : "ロックする"}
          >
            <IconButton
              aria-label={
                isLockedUpadateEndEvent ? "ロックを解除する" : "ロックする"
              }
              color="secondary"
              onClick={
                isLockedUpadateEndEvent
                  ? handleConfirmUnLockDialogOpen
                  : handleConfirmLockDialogOpen
              }
              size="small"
            >
              {isLockedUpadateEndEvent ? <LockIcon /> : <LockOpenIcon />}
            </IconButton>
          </Tooltip>
        </MuiBox>

        <MuiBox
          component="div"
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Typography variant="h1">かんり</Typography>
          <Tooltip title="最新情報に更新する">
            <IconButton
              aria-label="最新情報に更新する"
              color="secondary"
              onClick={handleSyncClick}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : done ? (
                <Fade in={done} timeout={777}>
                  <DoneIcon />
                </Fade>
              ) : (
                <SyncIcon />
              )}
            </IconButton>
          </Tooltip>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ marginLeft: 8 }}
          >
            {new Date(amidaEventTimestamp).toLocaleString()}
          </Typography>
        </MuiBox>

        {isBlockMessageTypography()}

        <Typography variant="body1">
          参加者 ( {amidaEvent.players.length} 人)
        </Typography>
        <Typography variant="body2">
          {amidaEvent.players
            .map((player) => player.displayName?.val)
            .join(", ")}
        </Typography>
        {amidaEvent.players.length < 2 && (
          <Typography variant="body1" color="textSecondary">
            参加者が2人以上で、あみだくじを開始できます。
          </Typography>
        )}
        <Typography variant="body1">振り分け</Typography>
        <Typography variant="body2">
          {getCandidateLabel(
            amidaEvent.players.length,
            amidaEvent.playerLabels.map((pl) => pl.labelName)
          )}
        </Typography>
        <MuiBox component="div" display="flex" flexDirection="row">
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => navigate("/e/" + amidaEvent.id + "/edit")}
            disabled={isDisabledEditButton()}
          >
            <Typography variant="body1">編集する</Typography>
          </Button>

          <MuiBox component="div" m={1} />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              handleConfirmDialogOpen();
            }}
            disabled={isDisabledEndButton()}
          >
            <Typography variant="body1" style={{ whiteSpace: "nowrap" }}>
              開始する
            </Typography>
          </Button>
        </MuiBox>
      </Paper>

      {/* 実行確認ダイアログ */}
      <Dialog open={openConfirmDialog} onClose={handleConfirmDialogClose}>
        <DialogTitle>かくにん</DialogTitle>
        <DialogContent>
          <DialogContentText>
            参加受付を終了して、あみだくじを開始しますか？
          </DialogContentText>
          {
            // パスワードが必要な場合のメッセージ
            isLockedUpadateEndEvent && (
              <>
                <Typography variant="body1" color="textSecondary">
                  このあみだくじの開始にはパスワードが必要です。
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="パスワードを入力"
                  variant="outlined"
                  value={inputPassword}
                  onChange={(e) => {
                    setInputPassword(e.target.value);
                  }}
                  inputRef={textFieldRef}
                  onKeyDown={handleKeyDown}
                  inputProps={{
                    enterKeyHint: "done",
                    type: "text",
                  }}
                />
              </>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmDialogClose}
            variant="contained"
            color="secondary"
          >
            <Typography variant="body1">キャンセル</Typography>
          </Button>
          <MuiBox component="div" m={0.5} />
          <Button
            onClick={handleConfirmEndEvent}
            variant="contained"
            color="primary"
          >
            <Typography variant="body1" width="70px">
              確認
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>

      {/* ロック確認ダイアログ */}
      <Dialog
        open={openConfirmLockDialog}
        onClose={handleConfirmLockDialogClose}
      >
        <DialogTitle>ロック</DialogTitle>
        <DialogContent>
          {isDoneLockUpdateEndEvent ? (
            <>
              <DialogContentText>
                ロックしました。パスワードは <b>{providedPassword}</b> です。
              </DialogContentText>
              <Typography variant="body1" color="textSecondary">
                パスワードを忘れないようにしてください。今後、パスワードは表示されません。
              </Typography>
            </>
          ) : (
            <>
              <DialogContentText>
                パスワードでロックしますか？
              </DialogContentText>
              <Typography variant="body1" color="textSecondary">
                あみだくじの編集・開始にはパスワードが必要になります。
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {
            // ロック完了後のボタン表示
            isDoneLockUpdateEndEvent ? (
              <Button
                onClick={handleConfirmLockDialogClose}
                variant="contained"
                color="primary"
              >
                <Typography variant="body1">閉じる</Typography>
              </Button>
            ) : (
              <>
                <Button
                  onClick={handleConfirmLockDialogClose}
                  variant="contained"
                  color="secondary"
                >
                  <Typography variant="body1">キャンセル</Typography>
                </Button>
                <MuiBox component="div" m={0.5} />
                <Button
                  onClick={handleConfirmLockUpdateEndEvent}
                  variant="contained"
                  color="primary"
                >
                  <Typography variant="body1" width="70px">
                    確認
                  </Typography>
                </Button>
              </>
            )
          }
        </DialogActions>
      </Dialog>

      {/* アンロック確認ダイアログ */}
      <Dialog
        open={openConfirmUnLockDialog}
        onClose={handleConfirmUnLockDialogClose}
      >
        <DialogTitle>ロック解除</DialogTitle>
        <DialogContent>
          {isDoneUnLockUpdateEndEvent ? (
            <DialogContentText>ロックを解除しました。</DialogContentText>
          ) : (
            <>
              <DialogContentText>ロックを解除しますか？</DialogContentText>
              <Typography variant="body1" color="textSecondary">
                あみだくじの編集・開始にパスワードが不要になります。
              </Typography>
              <TextField
                fullWidth
                id="outlined-basic"
                label="パスワードを入力"
                variant="outlined"
                value={inputPassword}
                onChange={(e) => {
                  setInputPassword(e.target.value);
                }}
                inputRef={textFieldRef}
                onKeyDown={handleKeyDown}
                inputProps={{
                  enterKeyHint: "done",
                  type: "text",
                }}
              />

              {/* 管理者による強制ロック解除 */}
              {!isShowUnlockByAdminUserOnOpenConfirmUnLockDialog ? (
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    textAlign: "right",
                  }}
                  onClick={() => {
                    setIsShowUnlockByAdminUserOnOpenConfirmUnLockDialog(true);
                  }}
                >
                  忘れたとき
                </Typography>
              ) : (
                <>
                  <MuiBox component="div" m={3} />
                  <MuiBox
                    component="div"
                    borderBottom={1}
                    borderColor="text.secondary"
                  />
                  <MuiBox component="div" m={3} />

                  <Typography variant="body1">
                    管理者の場合、パスワードを入力せずにロックを強制解除できます。
                  </Typography>

                  {isAdmin ? (
                    <>
                      <Typography variant="body1" color="textSecondary">
                        あなたは管理者です。パスワードを入力せずにロックを強制解除できます。
                      </Typography>
                      <Button
                        onClick={handleConfirmUnLockUpdateEndEventByAdminUser}
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        <Typography variant="body1">
                          ロックを強制解除
                        </Typography>
                      </Button>
                    </>
                  ) : (
                    <Typography variant="body1" color="textSecondary">
                      あなたは管理者ではありません。管理者に連絡してください。
                    </Typography>
                  )}
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {isDoneUnLockUpdateEndEvent ? (
            <Button
              onClick={handleConfirmUnLockDialogClose}
              variant="contained"
              color="primary"
            >
              <Typography variant="body1">閉じる</Typography>
            </Button>
          ) : (
            <>
              <Button
                onClick={handleConfirmUnLockDialogClose}
                variant="contained"
                color="secondary"
              >
                <Typography variant="body1">キャンセル</Typography>
              </Button>
              <MuiBox component="div" m={0.5} />
              <Button
                onClick={handleConfirmUnLockUpdateEndEvent}
                variant="contained"
                color="primary"
              >
                <Typography variant="body1" width="70px">
                  確認
                </Typography>
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <MuiBox component="div" m={1} />

      {amidaEvent.id && (
        <ShareEvent
          title={"しょうたい"}
          eventId={amidaEvent.id}
          eventTitle={amidaEvent.title?.val ?? ""}
        />
      )}
    </>
  );
};

export default SuccessOpen;
