import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
        primary: {
            main: '#ff6b6b', // 明るいティールブルー（少し暗めに調整）
        },
        secondary: {
            main: '#008b8b', // 明るい赤
        },
        background: {
            default: '#1e1e2f', // ダークブルーグレー
            paper: '#2b2b3c', // やや明るいダークブルーグレー
        },
        text: {
            primary: '#FFFFFF', // 白色のテキスト
            secondary: '#00b3b3', // 明るいティールブルー
        },
        action: {
            active: '#ff6b6b', // アクティブな要素の色（明るい赤）
            hover: '#006b6b', // ホバー時の色（少し暗めに調整）
        },
        warning: {
            main: '#2b2b3c', // 背景に馴染むダークブルーグレー
        },
    },
    typography: {
        fontSize: 14,
        fontFamily: [
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        button: {
            textTransform: 'none', // ボタンテキストの大文字変換を無効にする
        },
        h1: {
            fontSize: '1.2rem',
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: '#1e1e2f', // 背景色をダークブルーグレーに変更
                    color: '#FFFFFF', // テキスト色を白色に変更
                    '& a': {
                        color: '#00b3b3', // リンクテキストの色を明るいティールブルーに変更
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#000000', // ボタンテキストの色を黒に変更
                    borderRadius: '12px',
                    boxShadow: '8px 8px 16px #1a1a2a, -8px -8px 16px #22223b', // ニューモーフィズムのシャドウ
                    '&:hover:active': {
                        boxShadow: '8px 8px 16px #1a1a2a, -8px -8px 16px #22223b', // ニューモーフィズムのシャドウ
                    },
                    margin: '8px 0', // ボタンのマージンを変更
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1e1e2f', // アプリバーの背景色
                    color: '#FFFFFF', // アプリバーのテキスト色
                    borderRadius: '12px',
                    marginBottom: '16px',
                    boxShadow: '8px 8px 16px #1a1a2a, -8px -8px 16px #22223b', // ニューモーフィズムのシャドウ
                    height: '76px',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#141421', // ペーパーコンポーネントの背景色
                    color: '#FFFFFF', // ペーパーコンポーネントのテキスト色
                    borderRadius: '12px',
                    boxShadow: '8px 8px 16px #1a1a2a, -8px -8px 16px #22223b', // ニューモーフィズムのシャドウ
                    padding: '16px',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#FFFFFF', // デフォルトのテキストカラーをホワイトに
                    margin: '8px 0', // デフォルトのマージンを変更
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        backgroundColor: '#2b2b3c', // テキストフィールドの背景色
                        color: '#FFFFFF', // テキストフィールドのテキスト色
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#008b8b', // テキストフィールドの枠線の色
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#00b3b3', // ホバー時の枠線の色
                    },
                    '& .MuiInputLabel-root': {
                        color: '#00b3b3', // ラベルの色
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#2b2b3c', // Dialog background color
                    borderRadius: '12px',
                    boxShadow: '8px 8px 16px #1a1a2a, -8px -8px 16px #22223b', // Neumorphism shadow
                    padding: '16px',
                },
            },
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    color: '#FFFFFF', // DialogContentText text color
                },
            },
        },
    },
});

export default darkTheme;
