import React, { useEffect, useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Vector3, WebGLRenderer } from "three";
import { OrbitControls } from "@react-three/drei";
import { Box as MuiBox } from "@mui/material";
import { Player } from "../../../domain/types/Player";
import { Fab } from "@mui/material"; // 追加
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"; // 追加
import AmidaViewer from "./AmidaViwer/AmidaViewer";
import * as THREE from "three";

type Props = {
  playersSortedByStartIndex: Player[];
  myPlayerIndex: number;
  playerLabels: string[];
  playersSortedByGoalIndex: Player[];
};

const AmidaResult = (props: Props) => {
  const {
    playersSortedByStartIndex,
    myPlayerIndex,
    playerLabels,
    // playersSortedByGoalIndex,
  } = props;

  const Rig = ({ v = new Vector3() }) => {
    const isAnimating = useRef(true); // アニメーション中フラグ
    const start = useRef(Date.now());

    return useFrame((state) => {
      if (isAnimating.current) {
        state.camera.position.lerp(
          v.set(0, 0, playersSortedByStartIndex.length + 1.5),
          0.03
        );
        // カメラが目標位置に近づいたらアニメーションを停止
        // 途中で操作されて、目標位置に微妙にたどりつけない可能性があるので、時間でも false にする
        if (
          state.camera.position.distanceTo(
            v.set(0, 0, playersSortedByStartIndex.length + 1.5)
          ) < 0.01 ||
          Date.now() - start.current > 1900
        ) {
          isAnimating.current = false; // アニメーションを終了
        }
      }
    });
  };

  const renderer = useRef<WebGLRenderer | null>(null);
  useEffect(() => {
    return () => {
      // コンポーネントがアンマウントされたときに WebGL コンテキストを解放する
      if (renderer.current) {
        renderer.current.dispose();
        renderer.current = null;
      }
    };
  }, []);

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  const testJson = {
    env: {
      background: ["px.png", "nx.png", "py.png", "ny.png", "pz.png", "nz.png"],
    },
    eachPlayer: {
      type: "long",
      axis: "y",
      obj: "https://storage.com/y_long_obj.gltf",
    },
    bridge: {
      type: "long",
      axis: "x",
      obj: "https://storage.com/x_long_obj.gltf",
    },
    ball: {
      type: "move",
      obj: "https://storage.com/ball_obj.gltf",
    },
  };

  const stringJson = JSON.stringify(testJson);

  return (
    <>
      <MuiBox
        component="div"
        height={500}
        style={{
          userSelect: "none",
          WebkitUserSelect: "none",
          position: "relative",
        }}
      >
        <Canvas
          onCreated={({ gl, scene }) => {
            renderer.current = gl;
            gl.setPixelRatio(window.devicePixelRatio);
            gl.toneMapping = THREE.ACESFilmicToneMapping;
            gl.toneMappingExposure = 1.2;
            gl.outputColorSpace = THREE.SRGBColorSpace;
            scene.background = new THREE.Color(0x111111);
          }}
        >
          <AmidaViewer
            json={stringJson}
            playersSortedByStartIndex={playersSortedByStartIndex}
            playerLabels={playerLabels}
            myPlayerIndex={myPlayerIndex}
          />

          <OrbitControls
            enableZoom={true} // ズームを有効にする
            enablePan={true} // パンを有効にする
            enableRotate={true} // 回転を有効にする
            //スクロールを無視する
            onWheel={(e) => e.nativeEvent.preventDefault()}
          />

          <ambientLight intensity={1} />
          <directionalLight position={[5, 5, 5]} intensity={1} />
          <pointLight position={[-5, 5, 5]} intensity={0.5} />

          <Rig />
        </Canvas>
        <Fab
          color="secondary"
          onClick={scrollToBottom}
          style={{
            position: "absolute",
            bottom: 20,
            right: 20,
            zIndex: 1000,
            opacity: 0.7, // 半透明に設定
          }}
        >
          <ArrowDownwardIcon />
        </Fab>
      </MuiBox>
    </>
  );
};

export default AmidaResult;
