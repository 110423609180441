import React, { useEffect, useState, useRef, useCallback } from "react";
import { Sphere } from "@react-three/drei";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { Player } from "../../../../domain/types/Player";

type Props = {
  jsonObj?: BallJsonObj;
  playersSortedByStartIndex: Player[];
  myPlayerIndex?: number;
  playerLabels: string[];
};

export interface BallJsonObj {
  type: BallType;
  obj: string;
}

enum BallType {
  MOVE = "move",
  LONG = "long",
}

const Ball = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { jsonObj, playersSortedByStartIndex, myPlayerIndex, playerLabels } =
    props;

  const sphereSize = 0.07;
  const speed = 0.007;
  const [position, setPosition] = useState<THREE.Vector3>(
    new THREE.Vector3(0, 0, 0)
  );
  const [crossedBridgeIndex, setCrossedBridgeIndex] = useState<number>(0);
  const [currentEachPlayerIndex, setCurrentEachPlayerIndex] = useState<
    number | undefined
  >(myPlayerIndex);
  const [isRestarting, setIsRestarting] = useState(false);
  const restartTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const setInitialPosition = useCallback(() => {
    if (myPlayerIndex === undefined || myPlayerIndex === -1) {
      return;
    }
    const player = playersSortedByStartIndex[myPlayerIndex];
    const ball = player?.ball;
    if (ball === undefined) {
      return;
    }
    const startIndex = ball.startIndex;

    const initialPosX =
      (startIndex - (playersSortedByStartIndex.length - 1) / 2) * 1;
    const initialPosY = 1;
    const depth = 1;
    setPosition(new THREE.Vector3(initialPosX, initialPosY, depth));
    setCrossedBridgeIndex(0);
    setCurrentEachPlayerIndex(myPlayerIndex);
    setIsRestarting(false);
  }, [myPlayerIndex, playersSortedByStartIndex]);

  //初期値の設定
  useEffect(() => {
    setInitialPosition();
  }, [setInitialPosition]);

  //時間経過でボールを動かす
  useFrame(() => {
    if (isRestarting) return;

    setPosition((prev) => {
      const currenPos = prev;
      if (currenPos.y <= -1) {
        if (!restartTimeoutRef.current) {
          setIsRestarting(true);
          restartTimeoutRef.current = setTimeout(() => {
            setInitialPosition();
            restartTimeoutRef.current = null;
          }, 3000); // 3秒後に再開
        }
        return currenPos;
      }
      if (myPlayerIndex === undefined || myPlayerIndex === -1) {
        return currenPos;
      }
      const player = playersSortedByStartIndex[myPlayerIndex];

      const ball = player?.ball;
      if (ball === undefined) {
        return currenPos;
      }
      if (ball.histories.length <= crossedBridgeIndex) {
        return new THREE.Vector3(prev.x, prev.y - speed, prev.z);
      }
      const nextBridge = ball.histories[crossedBridgeIndex];
      const nextBridgeHeight = nextBridge.bridge.relativeHeight;
      if (nextBridgeHeight === undefined) {
        return currenPos;
      }

      // pos は -1 から 1 までの値を取るが、bridge の位置は 1 から 0 までの値を取る
      const relativeCurrentPosY = (currenPos.y + 1) / 2;

      if (relativeCurrentPosY < nextBridgeHeight) {
        switch (ball.histories[crossedBridgeIndex].toward) {
          case "left":
            if (
              currentEachPlayerIndex === undefined ||
              currentEachPlayerIndex === 0
            ) {
              return new THREE.Vector3(prev.x + speed, prev.y, prev.z);
            }
            const leftEachPlayerX =
              (currentEachPlayerIndex -
                1 -
                (playersSortedByStartIndex.length - 1) / 2) *
              1;

            if (currenPos.x <= leftEachPlayerX) {
              setCrossedBridgeIndex(crossedBridgeIndex + 1);
              setCurrentEachPlayerIndex(currentEachPlayerIndex - 1);
            }
            return new THREE.Vector3(prev.x - speed, prev.y, prev.z);
          case "right":
            if (
              currentEachPlayerIndex === undefined ||
              currentEachPlayerIndex === playerLabels.length - 1
            ) {
              return new THREE.Vector3(prev.x - speed, prev.y, prev.z);
            }
            const rightEachPlayerX =
              (currentEachPlayerIndex +
                1 -
                (playersSortedByStartIndex.length - 1) / 2) *
              1;

            if (currenPos.x >= rightEachPlayerX) {
              setCrossedBridgeIndex(crossedBridgeIndex + 1);
              setCurrentEachPlayerIndex(currentEachPlayerIndex + 1);
            }
            return new THREE.Vector3(prev.x + speed, prev.y, prev.z);
          default:
            return new THREE.Vector3(prev.x, prev.y, prev.z);
        }
      }

      return new THREE.Vector3(prev.x, prev.y - speed, prev.z);
    });
  });

  return (
    <>
      {myPlayerIndex === undefined || myPlayerIndex === -1 ? null : (
        <Sphere args={[sphereSize, 32, 32]} position={position}>
          <meshStandardMaterial emissive="red" color="red" />
        </Sphere>
      )}
    </>
  );
};

export default Ball;
